import React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'
import { column } from 'styles/column'
import { clearfix } from 'styles/clearfix'
import Card, { cardType } from './card'

const ProductCategoryGrid = ({ categories, ...props }) => {
  return (
    <Grid {...props}>
      {categories.map(({ containerProps = {}, ...cat }) => (
        <Column key={cat.title} {...containerProps}>
          <Card {...cat} />
        </Column>
      ))}
    </Grid>
  )
}

ProductCategoryGrid.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.shape(cardType)).isRequired,
}

const Grid = styled.div`
  ${clearfix}
`

const Column = styled.div`
  margin-bottom: ${props => props.theme.size.xl};

  @media ${props => props.theme.mediaQuery.small} {
    ${column('1/2', 16)}
  }

  @media ${props => props.theme.mediaQuery.smallUp} {
    ${column('1/2', 16)}
    margin-bottom: ${props => props.theme.size.xxl};
  }

  @media ${props => props.theme.mediaQuery.mediumUp} {
    ${column('1/3', 16)}
  }

  @media ${props => props.theme.mediaQuery.largeUp} {
    ${column('1/4', 16)}
  }

  @media ${props => props.theme.mediaQuery.max} {
    ${column('1/6', 16)}
  }
`

export default ProductCategoryGrid

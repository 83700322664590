import React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import AspectRatioContainer from 'components/aspect-ratio-container'

const ProductCategoryGridCard = ({ image, imageAlt, link, title }) => {
  return (
    <CardLink to={link}>
      <AspectRatioContainer ratio={80}>
        <GatsbyImage
          image={getImage(image)}
          alt={imageAlt}
          objectFit='contain'
          objectPosition='50% 50%'
          style={{ height: '100%' }}
        />
      </AspectRatioContainer>
      <Title>{title}</Title>
    </CardLink>
  )
}

export const cardType = {
  imageAlt: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

ProductCategoryGridCard.propTypes = cardType

ProductCategoryGridCard.defaultProps = {
  imageAlt: '',
}

const CardLink = styled(Link)`
  color: ${props => props.theme.color.n800};
  display: block;
  text-decoration: none;
  text-align: center;

  :hover,
  :focus {
    text-decoration: underline;
  }
`

const Title = styled.span`
  display: block;
  font-size: 18px;
  font-weight: 700;
  margin-top: ${props => props.theme.size.m};
  text-align: center;
`

export default ProductCategoryGridCard

import React from 'react'
import styled from 'styled-components'
import { HeroImageTitle } from 'components/new/hero'
import H2 from 'components/new/typography/h2'
import H3 from 'components/new/typography/h3'
import H4 from 'components/new/typography/h4'
import P from 'components/new/typography/p'
import { Helmet } from 'react-helmet'
import Layout from 'components/layout'
import Content from 'components/new/content'
import { graphql, Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { column } from 'styles/column'
import { clearfix } from 'styles/clearfix'
import Button from 'components/button/button'
import Spacer from 'components/new/spacer'
import AdditionalResources from 'components/new/additional-resources'
import ProductCategoryGrid from 'components/new/product-category-grid'
import { OutboundLink } from 'gatsby-plugin-google-gtag'

const items = [
  {
    link: '/lawn-garden/commercial-mowers/',
    imageId: 'commercialZtraksImage',
    title: 'Commercial Mowers',
    imageAlt: 'John Deere commercial mower',
  },
  {
    link: '/lawn-garden/gators/',
    imageId: 'gatorsImage',
    title: 'Gator Utility Vehicles',
    imageAlt: 'John Deere Gator',
  },
  {
    link: '/agriculture/utility-tractors/',
    imageId: 'utilityTractorsImage',
    title: 'Utility Tractors',
    imageAlt: 'John Deere utility tractor',
  },
  {
    link: '/agriculture/cutters-shredders/rotary-cutters/',
    imageId: 'rotaryCutterImage',
    title: 'Rotary Cutters',
    imageAlt: 'John Deere rotary cutter',
  },
  {
    link: '/construction/excavators/compact-excavators/',
    imageId: 'excavatorsImage',
    title: 'Compact Excavators',
    imageAlt: 'John Deere mini excavator',
  },
  {
    link: '/construction/',
    imageId: 'skidSteersImage',
    title: 'Compact Construction Equipment',
    imageAlt: 'John Deere skid steer',
  },
  {
    link: '/product-lines/manitou/',
    imageId: 'manitouImage',
    title: 'Manitou',
    imageAlt: 'Manitou MT 625 H telehandler',
  },
  {
    link: '/product-lines/alamo-industrial/',
    imageId: 'alamoIndustrialImage',
    title: 'Alamo',
    imageAlt: 'Alamo',
  },
  {
    link: '/product-lines/mantis/',
    imageId: 'mantisImage',
    title: 'Mantis',
    imageAlt: 'Mantis',
  },
  {
    link: '/product-lines/diamond-mowers/',
    imageId: 'diamondMowersImage',
    title: 'Diamond Mowers',
    imageAlt: 'Diamond Mowers',
  },
  {
    link: '/product-lines/billy-goat/',
    imageId: 'billyGoatImage',
    title: 'Billy Goat',
    imageAlt: 'Billy Goat',
  },
  {
    link: '/stihl/',
    imageId: 'chainsawsImage',
    title: 'STIHL',
    imageAlt: 'STIHL chainsaw',
  },
]

const specialists = [
  {
    imageKey: 'alexHeadshot',
    name: 'Alex Hahn',
    title: 'Director of Governmental & Commercial Sales',
    officePhone: '270-408-1397',
    mobilePhone: '618-638-7284',
    email: 'ahahn@hutsoninc.com',
  },
  {
    imageKey: 'mattHeadshot',
    name: 'Matt Lohone',
    title: 'Governmental Sales Manager',
    locations: 'Northern Michigan',
    mobilePhone: '517-749-6218',
    email: 'mlohone@hutsoninc.com',
  },
  {
    imageKey: 'mikeHeadshot',
    name: 'Mike Hugenot',
    title: 'Governmental Sales Manager',
    locations: 'Central Michigan',
    officePhone: '517-655-4606',
    mobilePhone: '517-819-4177',
    email: 'mhugenot@hutsoninc.com',
  },
  {
    imageKey: 'cameronHeadshot',
    name: 'Cameron Valade',
    title: 'Governmental & Commercial Account Manager',
    locations: 'Southern Michigan',
    mobilePhone: '248-719-8044',
    email: 'cvalade@hutsoninc.com',
  },
  {
    imageKey: 'jeremyHeadshot',
    name: 'Jeremy Rice',
    title: 'Governmental Sales Manager',
    locations: 'Indiana',
    mobilePhone: '812-686-3370',
    email: 'jeremy.rice@hutsoninc.com',
  },
  {
    imageKey: 'samHeadshot',
    name: 'Sam Harris',
    title: 'Governmental Sales Manager',
    locations: 'Kentucky & Tennessee',
    officePhone: '931-647-0029',
    mobilePhone: '931-802-1972',
    email: 'sharris@hutsoninc.com',
  },
  {
    imageKey: 'daveHeadshot',
    name: 'Dave White',
    title: 'Governmental & Manitou Specialist',
    locations: 'Michigan, Kentucky, Tennessee, Illinois & Indiana',
    mobilePhone: '313-300-7001',
    email: 'dwhite@hutsoninc.com',
  },
]

const GovernmentSales = props => {
  const {
    data: {
      heroImage,
      johnDeereContractsImage,
      manitouGovernmentPurchasingImage,
      municipalLeasingImage,
    },
    data,
  } = props
  const gridItems = items.map(item => {
    item.image = data[item.imageId]
    return item
  })
  return (
    <Layout>
      <Helmet>
        <title>Government Sales | Hutson Inc</title>
        <meta
          name='description'
          content='Hutson carries a wide range of equipment for handling any job. Learn more about what Hutson has to offer and what sets us apart from other John Deere dealerships.'
        />
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            'itemListElement': [
              {
                '@type': 'ListItem',
                'position': 1,
                'name': 'Hutson Inc',
                'item': 'https://www.hutsoninc.com/',
              },
              {
                '@type': 'ListItem',
                'position': 2,
                'name': 'Government Sales',
                'item': 'https://www.hutsoninc.com/government-sales/',
              },
            ],
          })}
        </script>
      </Helmet>

      <Hero
        image={heroImage.childImageSharp.gatsbyImageData}
        title='Government Sales'
        overlayOpacity={0}
      />

      <Content kind='full'>
        <H2>Why Hutson?</H2>
        <P>
          Hutson Inc understands making the most of your time and funds is a critical factor of your
          job. John Deere understands that too. Government agencies and municipalities that go
          through a John Deere dealer like Hutson don't have to deal with the bid process. We carry
          a range of equipment including: Gator UTVs, commercial mowers, light construction
          equipment, a full range of tractors, handheld power equipment and much more.
        </P>
        <P>
          What makes Hutson different than other John Deere dealers? It’s the people in our shops
          and parts counters. We’re here to take care of you after the sale of the equipment. Fast,
          and correct repairs will get your team back on the job. Plus, you have a dedicated account
          manager to make sure you are taken care of. If you have any questions about how the
          process works, please do not hesitate to call, text, or email one of our specialists.
        </P>
        <Spacer size='l' />
        <SpecialistGrid>
          {specialists.map(specialist => (
            <SpecialistColumn>
              {specialist.imageKey ? (
                <SpecialistHeadshot
                  image={data[specialist.imageKey].childImageSharp.gatsbyImageData}
                  alt={specialist.name}
                />
              ) : null}
              <div>
                <H4 as={SpecialistName}>{specialist.name}</H4>
                <p>{specialist.title}</p>
                {specialist.locations ? <p>{specialist.locations}</p> : null}
                {specialist.officePhone ? (
                  <p>
                    Office: <a href={`tel:${specialist.officePhone}`}>{specialist.officePhone}</a>
                  </p>
                ) : null}
                {specialist.mobilePhone ? (
                  <p>
                    Cell: <a href={`tel:${specialist.mobilePhone}`}>{specialist.mobilePhone}</a>
                  </p>
                ) : null}
                {specialist.email ? (
                  <p>
                    <a href={`mailto:${specialist.email}`}>{specialist.email}</a>
                  </p>
                ) : null}
              </div>
            </SpecialistColumn>
          ))}
        </SpecialistGrid>
      </Content>
      <LightBackground>
        <Content kind='full'>
          <Grid>
            <Column>
              <GatsbyImage image={johnDeereContractsImage.childImageSharp.gatsbyImageData} alt='' />
              <ColumnContent>
                <H3>John Deere State, Local & Cooperative Contracts</H3>
                <P>
                  With John Deere you have multiple options when it comes to getting the equipment
                  you need. And you don't have to worry about the bidding process because that's
                  already been taken care of. Browse state and cooperative contracts on Deere.com.
                </P>
                <Spacer size='s' />
                <ButtonContainer>
                  <Button
                    as={OutboundLink}
                    href='https://www.deere.com/en/government-and-military-sales/state-and-local-purchasing/'
                    ghost
                    color='green'
                  >
                    Learn more at Deere.com
                  </Button>
                </ButtonContainer>
              </ColumnContent>
            </Column>
            <Column>
              <GatsbyImage image={municipalLeasingImage.childImageSharp.gatsbyImageData} alt='' />
              <ColumnContent>
                <H3>John Deere Municipal Leasing Programs</H3>
                <P>
                  Explore low-cost, low-risk alternative routes to outright purchase equipment or an
                  installment loan with John Deere Municipal and Operating Lease options.
                </P>
                <Spacer size='s' />
                <ButtonContainer>
                  <Button
                    as={OutboundLink}
                    href='https://www.deere.com/en/government-and-military-sales/state-and-local-purchasing/municipal-leasing/'
                    ghost
                    color='green'
                  >
                    Learn more at Deere.com
                  </Button>
                </ButtonContainer>
              </ColumnContent>
            </Column>
            <Column>
              <GatsbyImage
                image={manitouGovernmentPurchasingImage.childImageSharp.gatsbyImageData}
                alt=''
              />
              <ColumnContent>
                <H3>Manitou Government Purchasing</H3>
                <P>
                  Learn more about government purchasing programs available for Manitou lifting and
                  loading equipment, parts, and attachments.
                </P>
                <Spacer size='s' />
                <ButtonContainer>
                  <Button
                    as={OutboundLink}
                    href='https://www.manitou.com/en-US/government-purchasing'
                    ghost
                    color='green'
                  >
                    Learn more at Manitou.com
                  </Button>
                </ButtonContainer>
              </ColumnContent>
            </Column>
          </Grid>
        </Content>
      </LightBackground>
      <Content kind='full'>
        <EquipmentH2>Explore Our Equipment</EquipmentH2>
        <Spacer size='l' />
        <ProductCategoryGrid categories={gridItems} />
      </Content>
      <AdditionalResources
        resources={[
          {
            title: 'Parts & Service',
            links: [
              <Link to='/parts/'>Parts at Hutson</Link>,
              <Link to='/service/'>Service at Hutson</Link>,
            ],
          },
          {
            title: 'Hutson Inc',
            links: [<Link to='/locations/'>Locations</Link>, <Link to='/about/'>About Us</Link>],
          },
          {
            title: 'Warranties',
            links: [<Link to='/powergard/'>PowerGard Protection Plan</Link>],
          },
        ]}
      />
    </Layout>
  )
}

const LightBackground = styled.div`
  background-color: ${props => props.theme.color.n20};
`

const Hero = styled(HeroImageTitle)`
  height: 300px;

  @media (min-width: 900px) {
    height: 450px;
  }
`

const SpecialistGrid = styled.div`
  @media (min-width: 800px) {
    ${clearfix}
  }
`

const SpecialistHeadshot = styled(GatsbyImage)`
  max-width: 120px;
  width: 100%;

  @media (min-width: 800px) {
    max-width: 90px;
  }
`

const SpecialistColumn = styled.div`
  background-color: ${props => props.theme.color.n10};
  padding: 18px;
  text-align: center;

  :not(:last-child) {
    margin-bottom: 24px;
  }

  a {
    color: ${props => props.theme.color.g400};
    text-decoration: underline;
  }

  p {
    font-size: 15px;
    line-height: 1.3;
    margin: 6px 0 0;
  }

  ${SpecialistHeadshot} {
    margin: 0 auto 16px;
  }

  @media (min-width: 600px) {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    text-align: left;

    ${SpecialistHeadshot} {
      margin: 0 36px 0 0;
    }
  }

  @media (min-width: 800px) {
    ${column('1/2')}
  }

  @media (min-width: 1200px) {
    ${column('1/3')}
  }
`

const SpecialistName = styled.span`
  font-size: 18px;
  font-weight: 600;
`

const EquipmentH2 = styled(H2)`
  text-align: center;
`

const ButtonContainer = styled.div`
  a {
    display: inline-block;
    text-decoration: none;
  }
`

const Grid = styled.div`
  @media (min-width: 900px) {
    ${clearfix}
    display: flex;
  }
`

const Column = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;

  p {
    flex-grow: 2;
  }

  @media (max-width: 899px) {
    :not(:last-child) {
      margin-bottom: ${props => props.theme.size.l};
    }
  }

  @media (min-width: 900px) {
    ${column('1/3')}
  }
`

const ColumnContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 2;
  padding: ${props => props.theme.size.m};
`

export const pageQuery = graphql`
  {
    heroImage: file(relativePath: { eq: "government/government-sales-hero.jpg" }) {
      ...FullWidthImage
    }
    alexHeadshot: file(relativePath: { eq: "government/alex-hahn-headshot.png" }) {
      childImageSharp {
        gatsbyImageData(width: 180)
      }
    }
    cameronHeadshot: file(relativePath: { eq: "government/cameron-valade-headshot.png" }) {
      childImageSharp {
        gatsbyImageData(width: 180)
      }
    }
    samHeadshot: file(relativePath: { eq: "government/sam-harris-headshot.png" }) {
      childImageSharp {
        gatsbyImageData(width: 180)
      }
    }
    jeremyHeadshot: file(relativePath: { eq: "government/jeremy-rice-headshot.png" }) {
      childImageSharp {
        gatsbyImageData(width: 180)
      }
    }
    mattHeadshot: file(relativePath: { eq: "government/matt-lohone-headshot.png" }) {
      childImageSharp {
        gatsbyImageData(width: 180)
      }
    }
    daveHeadshot: file(relativePath: { eq: "government/dave-white-headshot.png" }) {
      childImageSharp {
        gatsbyImageData(width: 180)
      }
    }
    mikeHeadshot: file(relativePath: { eq: "government/mike-hugenot-headshot.png" }) {
      childImageSharp {
        gatsbyImageData(width: 180)
      }
    }
    johnDeereContractsImage: file(
      relativePath: { eq: "government/john-deere-state-local-coop-contracts.jpg" }
    ) {
      ...SharpImage480
    }
    municipalLeasingImage: file(
      relativePath: { eq: "government/john-deere-municipal-leasing.jpg" }
    ) {
      ...SharpImage480
    }
    manitouGovernmentPurchasingImage: file(
      relativePath: { eq: "government/manitou-government-purchasing.jpg" }
    ) {
      ...SharpImage480
    }
    commercialZtraksImage: file(relativePath: { eq: "government/commercial-mowers.jpg" }) {
      ...FloatingGridImage
    }
    gatorsImage: file(relativePath: { eq: "government/gators.jpg" }) {
      ...FloatingGridImage
    }
    skidSteersImage: file(relativePath: { eq: "construction/skid-steers.jpg" }) {
      ...FloatingGridImage
    }
    utilityTractorsImage: file(relativePath: { eq: "agriculture/6-family.jpg" }) {
      ...FloatingGridImage
    }
    excavatorsImage: file(relativePath: { eq: "government/excavators.jpg" }) {
      ...FloatingGridImage
    }
    chainsawsImage: file(relativePath: { eq: "stihl/chain-saws.jpg" }) {
      ...FloatingGridImage
    }
    diamondMowersImage: file(relativePath: { eq: "government/diamond-mowers-logo.jpg" }) {
      ...FloatingGridImage
    }
    alamoIndustrialImage: file(relativePath: { eq: "government/alamo-industrial-logo.jpg" }) {
      ...FloatingGridImage
    }
    billyGoatImage: file(relativePath: { eq: "government/billy-goat-brushcutter.jpg" }) {
      ...FloatingGridImage
    }
    mantisImage: file(relativePath: { eq: "government/mantis-logo.jpg" }) {
      ...FloatingGridImage
    }
    rotaryCutterImage: file(relativePath: { eq: "cutters-shredders/rotary-cutter.jpg" }) {
      ...FloatingGridImage
    }
    manitouImage: file(relativePath: { eq: "government/manitou-mt-625-h-telehandler.jpg" }) {
      ...FloatingGridImage
    }
  }
`

export default GovernmentSales
